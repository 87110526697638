<template>
  <div class="pa-4" style="max-width: 1000px">
    <v-row>
      <v-col cols="12" md="9">
        <h1 class="ggfontbold greytext mt-4">
          <span style="font-size:40px"
            >EisbaumTabelle | Katze <sup class="katzetext">Neu</sup></span
          >
        </h1>

        <span class="katzetext" style="font-weight:normal;font-size:24px;"
          >Die besten Katzen-Krankenversicherungen im Eisbaum-Vergleich</span
        >
        <ul class="ml-4 my-4">
          <li>
            Die besten Tierkrankenversicherungen für Katzen im Test
          </li>
          <li>
            Diese Katzenkrankenversicherungen erstatten bis zum 4-fachen Satz
            der GOT
          </li>
          <li>
            Katzen-Tarife die sowohl für Tier-Behandlungen wie für
            Tier-Operationen leisten
          </li>
        </ul>

        Wir haben gemeinsam mit Tierärzten und Tierkliniken ein Testverfahren
        für Tierkrankenversicherungen entwickelt, das die reale Leistungsstärke
        von Katzenkrankenversicherungen bewertet und vergleichbar macht. Dieses
        Testverfahren - EisbaumTabelle 5-Jahres-Realwert-Methode - bewertet
        Katzenkrankenversicherungen anhand der zwei tiermedizinischen
        Leistungsbereiche: Tierbehandlungen und Tier-Operationen. Katzenhalter
        können so die leistungsstärksten Katzenkrankenversicherungen miteinander
        vergleichen und die beste Tierkrankenversicherung für ihre Katze
        auswählen.
        <br /><br />
        <b>Tierkrankenversicherungen Katze - Jetzt online vergleichen</b>
        In nur wenigen Augenblicken werden Ihnen alle wichtigen Leistungen und
        Preise der besten Katzenkrankenversicherungen angezeigt. Starten Sie
        jetzt einen kostenlosen Online-Vergleich für
        Katzenkrankenversicherungen.
        <v-btn
          href="/vergleich_katze"
          class="nocaps mt-4"
          style="background-color: #fecf0c; color: #fff"
          >jetzt Katzenversicherungen vergleichen | Preise berechnen</v-btn
        >

        <v-card elevation="4" class="pb-2 my-8">
          <v-simple-table dense>
            <br />
            <thead>
              <tr class="katzeground">
                <th colspan="5">
                  <h3 class="py-2 white--text">
                    Die besten Katzenkrankenversicherungen im Test
                  </h3>
                </th>
              </tr>
              <tr style="background-color:#f2f2f2">
                <th class="py-2">Testsieger 2024</th>
                <th class="py-2">Ø-Erstattung</th>

                <th class="py-2" v-if="!isMobile">
                  Tierbehandlung in % (jährliche Begrenzung in €)
                </th>
                <th class="py-2" v-if="!isMobile">
                  Tieroperationen in % (jährliche Begrenzung in €)
                </th>
                <th class="py-2" v-if="!isMobile">mtl. Beitrag</th>
              </tr>
            </thead>
            <tbody>
              <tr
                style="vertical-align: top"
                v-for="item in tarife"
                :key="item.tier_id"
              >
                <td>
                  <a
                    ><router-link
                      :to="`/Krankenversicherung_Katze/${item.tier_url}`"
                      >{{ item.tier_title }}</router-link
                    >
                  </a>
                </td>
                <td>
                  <a @click="item.show_edit = !item.show_edit"
                    ><b>{{ item.tier_wert }} %</b></a
                  >

                  <v-dialog
                    v-if="item.show_edit"
                    v-model="item.show_edit"
                    width="888"
                  >
                    <spezial-pop :item="item" type="rechenweg"></spezial-pop>
                  </v-dialog>
                </td>

                <td v-if="!isMobile">
                  <b>{{ item.tier_behandlung_erstattung_zahl }}</b
                  ><br />{{ item.tier_behandlung_erstattung_max_jahr }}
                </td>
                <td v-if="!isMobile">
                  <b>{{ item.tier_op_erstattung_zahl }}</b
                  ><br />{{ item.tier_op_erstattung_max_jahr }}
                </td>
                <td style="min-width:100px;" v-if="!isMobile">
                  <span v-if="item.tier_preis_est == 0">
                    <b>{{ item.preis }} €</b>
                  </span>
                  <span v-if="item.tier_preis_est == 1">
                    ca. {{ item.preis }} €
                    <a
                      @click="item.show_edit_2 = !item.show_edit_2"
                      class="caption"
                      ><br />Wieso ca.?</a
                    >
                    <v-dialog
                      v-if="item.show_edit_2"
                      v-model="item.show_edit_2"
                      width="888"
                    >
                      <spezial-pop :item="item" type="preis_est"></spezial-pop>
                    </v-dialog>
                  </span>
                </td>
              </tr>
              <tr>
                <td colspan="5">
                  <a @click="showNichtVergleicher = !showNichtVergleicher">
                    alle Katzen-Krankenversicherungen die wir getestet haben.</a
                  >
                </td>
              </tr>
            </tbody></v-simple-table
          >
        </v-card>
        <h3>Ist der Abschluss einer Katzenkrankenversicherung sinnvoll?</h3>
        Ja, der Abschluss einer Tierkrankenversicherung für Katzen macht aus
        drei Gründen Sinn.
        <br /><br />
        <b>1. Stark steigende Kosten in der Tiermedizin</b><br />
        Stark gestiegene Kosten für den Betrieb einer Tierarztpraxis (für
        Personal, Energie, etc.) und deutlich moderne Behandlungsmethoden für
        Katzen führen zu deutlich höheren Tierarztkosten. Die neue
        Gebührenordnung für Tierärzte (GOT) 2023 macht es offiziell:
        "Tierarztbesuche mit meiner Katze werden deutlich teurer.'' Im
        Durchschnitt werden Tierarztrechnungen in 2023 um 30 % - 40 % teurer.
        Hier lohnt es sich, mit einer Tierkrankenversicherung für Katzen
        vorzusorgen.
        <br /><br />
        <b>2. Hohe Tierarztrechnungen kommen immer zum falschen Zeitpunkt</b>
        <br />
        Umfassende Tierbehandlungen oder komplexe Tieroperationen für mehrere
        tausend Euro kommen nie zum richtigen Zeitpunkt. Meistens kommen Sie
        genau dann, wenn gerade ein Großteil des Ersparten für Auto, Haus oder
        Waschmaschine ausgegeben wurde. Damit unsere Katze im Ernstfall die
        Behandlung bekommt, die sie/er benötigt, ist es sinnvoll, eine
        Katzenkrankenversicherung abzuschließen. Die Erfahrung zeigt: Frühzeitig
        Geld für Tierarztrechnungen auf die Seite zu legen, funktioniert in den
        meisten Fällen nicht.
        <br /><br />
        <b>3. Mit gutem Gewissen zum Tierarzt</b><br />
        Nicht jeder Unfall ist lebensbedrohlich oder jede Katzenkrankheit
        verläuft akut. Oft hat der Katzenhalter “nur so ein Gefühl”, dass es
        seiner Katze nicht gut geht. Oder die Katze hat anfangs nur leichte
        Symptome. Auch in diesen Fällen sollte die Angst vor “unnötigen
        Tierarztrechnungen” keine Rolle spielen. Man sollte mit gutem Gewissen
        zum Tierarzt gehen können, ohne viel Zeit mit Abwägen oder Abwarten zu
        verlieren. In vielen Fällen ist der frühzeitige Tierarztbesuch wichtig
        und notwendig.
        <br /><br />
        <b>Fazit:</b> Ja, der Abschluss einer Katzenkrankenversicherung ist
        sinnvoll, damit die Katze im Ernstfall die Behandlung bekommt, die sie
        benötigt, ohne dass sich der Tierhalter um hohe Tierarztrechnungen
        sorgen muss. Gewährleisten Sie eine angemessene Gesundheitsversorgung
        für Ihre Katze, ohne auf etwaige Kosten zu achten.
        <br />
        <h3>
          Wie viel kostet eine leistungsstarke Krankenversicherung für Katzen?
        </h3>
        Wieviel eine Tierkrankenversicherung für eine Katze kostet, hängt
        entscheidend von diesen 4 Faktoren ab:
        <ul>
          <li>
            Leistungen der Tierkrankenversicherung für Vorsorge, Tier-Behandlung
            und Tier-Op
          </li>
          <li>Höhe der Selbstbeteiligung einer Katzenkrankenversicherung</li>
          <li>
            Versicherungsbedingungen (Wartezeit, Leistungsbegrenzungen,
            Ausschlüsse, etc.)
          </li>
          <li>Alter der Katze</li>
        </ul>
        Eine Tierkrankenversicherung für eine einjährige Katze kostet monatlich
        ca. 20 € - 45 €.
        <br /><br />
        <b>Experten-Tipp:</b> Viele Katzenkrankenversicherungen sind ähnlich
        leistungsstark wie Hundekrankenversicherungen, kosten aber nur einen
        Bruchteil des monatlichen Hunde Beitrags.
        <h3>
          Checkliste | Welche Leistungen sollte eine Tierkrankenversicherung für
          Katzen beinhalten?
        </h3>
        <b>1. Leistungen für alle tiermedizinischen Leistungsbereiche</b><br />
        Die Katzenkrankenversicherung sollte sowohl für Tierbehandlungen
        (inklusive Vorsorge) als auch für Tieroperationen leisten, um eine
        flexible Versorgung im Krankheitsfall und bei Unfällen sicherzustellen.
        <br /><br />
        <b>2. Selbstbeteiligung maximal 20 % </b><br />
        Die Selbstbeteiligung einer Tierkrankenversicherung für Katzen sollte 20
        % nicht überschreiten, um auch bei vermeintlichen “Kleinigkeiten” mit
        gutem Gewissen zum Tierarzt gehen zu können.
        <br /><br />
        <b>3. Leistungsbegrenzungen sollten nicht zu niedrig sein</b> <br />
        Die Leistungsbegrenzungen für Vorsorge, Tierbehandlung und Tier-Ops
        sollten berücksichtigt werden. Die meisten Tarife haben anfängliche oder
        dauerhafte Leistungsbegrenzungen. Man sollte darauf achten, dass diese
        nicht zu niedrig sind.
        <br /><br />
        <b>4. Leistung im Notdienst bis zum 4-fachen Satz der GOT</b> <br />
        Katzenkrankenversicherungen sollten im Notdienst bis zum 4-fachen Satz
        der Gebührenordnung für Tierärzte (GOT) inklusive Notdienstgebühr
        leisten, um auch nachts, an Wochenenden und Feiertagen mit gutem
        Gewissen zum Tierarzt gehen zu können.
        <br /><br />
        <b>5. Wartezeit maximal 3 Monate</b><br />
        Die Wartezeit für Katzenkrankenversicherungen sollte max. 3 Monate
        betragen, da für Leistungsfälle, die in diesem Zeitraum eintreten, nicht
        geleistet wird.
        <br /><br />
        <b>6. Leistungsausschlüsse vermeiden</b><br />
        Bei einigen Versicherungen sind Fehlentwicklungen und angeborene
        Krankheiten vom Versicherungsschutz ausgeschlossen. Deshalb sollte man
        sich vor Abschluss genau über die Leistungsausschlüsse des jeweiligen
        Tarifs informieren.

        <br /><br />
        <b>7. Alternative Behandlungsmethoden berücksichtigen</b> <br />
        Auch die Bedeutung alternativer Behandlungsmethoden (z.B. Akupunktur und
        Lasertherapie) nimmt im tiermedizinischen Bereich zu. Möchte man solche
        Behandlungen auch für seine Katze in Anspruch nehmen, sollte man darauf
        achten, dass die Katzenkrankenversicherung dafür leistet.
        <br /><br />
        <h3>OP-Versicherungen für Katzen decken nur einen Teil der Kosten</h3>
        Die Rechnung einer Katzenoperation kann sich schnell auf mehrere tausend
        Euro belaufen und damit Katzenhalter unerwartet in finanzielle
        Schwierigkeiten bringen. Deshalb sind Tier-Ops ein wichtiger
        Leistungsbereich einer modernen Katzenkrankenversicherung. Dagegen sind
        die Kosten einer einfachen Tierbehandlung (oder Prophylaxe) meist
        geringer, belaufen sich aber im Laufe eines Jahres auch auf mehrere
        hundert Euro. Aus diesem Grund sollte eine leistungsstarke
        Katzenkrankenversicherung sowohl für Tier-Operationen wie auch für
        Tierbehandlungen und Prophylaxe leisten.
        <br /><br />
        <b>4 Grenzen einer OP-Versicherung für Katzen:</b><br />

        1. Eine Tier-Operationsversicherung leistet nicht für regelmäßige
        Prophylaxe-Maßnahmen, wie zum Beispiel Impfungen und Parasiten Vorsorge,
        welche speziell für Freigänger-Katzen wichtig sind.
        <br /><br />
        2. Kosten für Tierarztbesuche und Diagnosemaßnahmen, die sehr oft
        benötigt werden, um überhaupt festzustellen, dass eine Tier-Operation
        durchgeführt werden muss, werden von der OP-Versicherung meist nicht
        übernommen. Die Kosten müssen mit der OP in unmittelbarem Zusammenhang
        stehen.
        <br /><br />
        3. Chronische Erkrankungen (z.B. Niereninsuffizienz, Diabetes) führen zu
        regelmäßig wiederkehrenden Tierarztrechnungen, die sich im Laufe eines
        Katzenlebens auf einen hohen Betrag summieren können. Hierfür leisten
        Tier OP-Versicherungen nicht.
        <br /><br />
        4. Ein operativer Eingriff ist in einigen Fällen nur eine Möglichkeit,
        die Beschwerden der Katze zu lindern. In vielen Fällen kommt auch eine
        tiermedizinische Behandlung in Frage. Die OP-Versicherung leistet dann
        nur für den operativen Eingriff.
        <br /><br />
        <h3>
          Müssen Hauskatzen anders krankenversichert werden als
          Freigänger-Katzen?
        </h3>
        Natürlich haben freilaufende Katzen ein höheres Risiko sich zu verletzen
        oder sich mit Parasiten bzw. Krankheiten zu infizieren, als ihre stets
        beaufsichtigten Artgenossen zu Hause. Aber auch Hauskatzen entwickeln
        Krankheiten (z.B. Allergien) und können sich im Haus verletzen. Aus
        diesem Grund empfehlen wir sowohl für Wohnungskatzen als auch für
        Freigänger Katzen eine Katzenkrankenversicherung abzuschließen.
        <br /><br />
        <h3>
          Testsieger Stiftung Warentest 12/2022 | Krankenvollversicherungen für
          Katzen
        </h3>
        Im Dezember 2022 hat Stiftung Warentest 78 Krankenvollversicherungen für
        Katzen getestet. Anhand von zwei unterschiedlich alten Modell-Katzen
        (junge Katze mit 6 Monaten / ältere Freigänger Katze mit 7 Jahren) und
        zwei Behandlung-Beispielen: 1. Tier-Operation eines komplizierten
        Knochenbruchs - Tiermedizinischen Kosten 3.000 € 2. Tier-Behandlung
        eines akuten Nierenversagens in der Tierklinik - Tiermedizinischen
        Kosten 1.500 € hat Stiftung Warentest das Leistungsniveau von
        Katzenkrankenversicherungen ermittelt. Zudem wurde berücksichtigt,
        welche Tierärztlichen Leistungen in den einzelnen Tarifen versichert
        sind und in welchem Umfang Krankheiten vom Versicherungsschutz
        ausgeschlossen sind.
        <br /><br />
        Stiftung Warentest Testergebnis 12/2022
        <ol>
          <li>Agila Tierkranken Exklusiv → Leistungsniveau: Sehr hoch</li>
          <li>
            Allianz TK Kleintiere Smart 100% + HB 2000 → Leistungsniveau: Sehr
            hoch/li>
          </li>
          <li>Helvetia PetCare Komfort → Leistungsniveau: Sehr hoch/li></li>
        </ol>

        <br />
        <b>Hinweis:</b> Die Tarife der GHV und Barmenia sind im Test nicht
        berücksichtigt, da diese ihre Tarife im Untersuchungszeitraum von
        Stiftung Warentest überarbeitet haben.
      </v-col>
      <v-col cols="12" md="3" xs="12">
        <menue-download />
      </v-col>
    </v-row>
    <v-dialog v-model="showNichtVergleicher" width="999">
      <index-no-vergleich tierart="katze" />
    </v-dialog>
  </div>
</template>

<script>
  import MenueDownload from "./../base/helper/MenueDownload";
  import baseMixin from "../mixins/baseMixin";
  import IndexNoVergleich from "./../rechner/IndexNoVergleich";

  export default {
    name: "start-katze",
    components: { MenueDownload, IndexNoVergleich },
    mixins: [baseMixin],
    data: function() {
      return {
        tarife: false,
        showNichtVergleicher: false,
        select: {
          type: "katze",
          preise: "ja",
          number: "array",
        },
      };
    },
    created() {
      this.setTitle();
      this.saveReferrer();
      this.getTarife();
    },

    methods: {
      saveReferrer() {
        var string = document.referrer,
          substring = ".google";
        var isGoogle = string.indexOf(substring) !== -1;
        if (isGoogle) {
          this.updateSeo.seo_title = document.title;
          this.$store.dispatch("saveReferrer", this.updateSeo);
        }
      },
      getTarife() {
        this.$store
          .dispatch("getTarife", this.select)
          .then((resp) => {
            if (resp.data.tarife) {
              this.tarife = resp.data.tarife;
              document.dispatchEvent(new Event("x-app-rendered"));
            }
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },

      setTitle() {
        this.$log.debug("title");
        document.title =
          "Tierkrankenversicherung Katze Vergleich - Die Testsieger 2024";
        let metadesc =
          "Finden Sie jetzt eine günstige Tierkrankenversicherungen für Ihre Katze. Vergleich der besten Katzenkrankenversicherungen 2023";
        this.saveReferrer();
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", metadesc);
        document.dispatchEvent(new Event("x-app-rendered"));
      },
    },
  };
</script>
